.footer-container{
    font-family: 'GenJyuuGothic';
    position: sticky;
    bottom: 0;
    width: calc(100vw - 4rem);
    padding: 1rem 2rem;
    margin-top: 2.5rem;
    background-image: url('../img/footer/footer\ w\ pattern.PNG');
    background-repeat: repeat-x;
    height: calc(200px - 2rem);
}

.footer-container .back-top-btn{
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
    left: -0.75rem;
    bottom: 215px;
    text-decoration: none;
    color: var(--mainblue);
    font-size: 1.5rem;
}

.footer-container .back-top-btn::after{
    position: absolute;
    bottom: -15px;
    left: -21px;
    width: 72px;
    content: "back to top";
    font-size: 0.8rem;
    font-family: 'GenJyuuGothic';
}

.footer-container .left-column{
    margin: 0;
    padding: 0;
    position: relative;
    left: 0;
    top: 30%;
    color: white;
    font-weight: 600;
    height: 70%;
}

.footer-container .right-column{
    position: absolute;
    bottom: 15%;
    right: 2rem;
    width: fit-content;
    display: flex;
    flex-direction: row-reverse;
    column-gap: 0.5rem;
    align-items: center;
}

.footer-container .left-column .sponsor p{
    margin: 0;
    font-size: 0.9rem;
}

.footer-container .left-column .sponsor p a{
    color: white;
}

.footer-container .left-column .terms-and-conditions{
    position: absolute;
    left: 0;
    bottom: 1rem;
    text-decoration: none;
    color: white;
    font-size: 0.6rem;
}

.footer-container .left-column .copyright{
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 0.6rem;
    margin: 0;
}

.footer-container .right-column .footer-item{
    display: block;
    text-decoration: none;
    color: white;
    height: fit-content;
    font-size: 1.9rem;
}

@media screen and (max-width: 576px){
    .footer-container .right-column{
        display: grid;
        grid-template-columns: auto auto auto;
        bottom: 5%;
        row-gap: 0.5rem;
    }

    .footer-container .right-column .footer-item:nth-child(3){
        grid-column: 1 / span 1;
        grid-row: 1/ span 1;
    }

    .footer-container .right-column .footer-item:nth-child(2){
        grid-column: 2 / span 1;
        grid-row: 1/ span 1;
    }

    .footer-container .right-column .footer-item:nth-child(1){
        grid-column: 3 / span 1;
        grid-row: 1/ span 1;
    }
}