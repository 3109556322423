@font-face {
  font-family: 'TanukiPermanentMarker';
  src: url('fonts/TanukiMagic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'GenJyuuGothic';
  src: url('fonts/GenJyuuGothic-Normal.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'GenJyuuGothic';
  src: url('fonts/GenJyuuGothic-ExtraLight.woff2');
  font-weight:200;
  font-style: normal;
}

@font-face{
  font-family: 'GenJyuuGothic';
  src: url('fonts/GenJyuuGothic-Light.woff2');
  font-weight:300;
  font-style: normal;
}

@font-face{
  font-family: 'GenJyuuGothic';
  src: url('fonts/GenJyuuGothic-Medium.woff2');
  font-weight:500;
  font-style: normal;
}

@font-face{
  font-family: 'GenJyuuGothic';
  src: url('fonts/GenJyuuGothic-Bold.woff2');
  font-weight:bold;
  font-style: normal;
}

@font-face{
  font-family: 'GenJyuuGothic';
  src: url('fonts/GenJyuuGothic-Heavy.woff2');
  font-weight:900;
  font-style: normal;
}


html{
  --mainblue: #1a2c43;
  overflow-x: hidden;
  font-size: 16px;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  font-family: 'TanukiPermanentMarker', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  cursor: pointer;
}

@media screen and (max-width: 375px){
  html{
    font-size: 12px;
  }
}
