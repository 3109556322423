.related-services{
    position: relative;
    width: 100vw;
    height: fit-content;
    overflow: hidden;
}

.related-services .main-text{
    position: relative;
    width: 80%;
    max-width: 960px;
    height: fit-content;
    margin: 0 auto;
    color: var(--mainblue);
}

.related-services .main-text h1{
    margin: 0 auto;
    width: fit-content;
    height: fit-content;
    font-size: 2.5rem;
    font-weight: normal;
    background-image: url('../img/Pages/7\ 相關服務/a_140x40.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.related-services .main-text p{
    margin: 50px auto;
    font-size: 1.3rem;
    font-family: 'GenJyuuGothic';
    line-height: 1.6;
    text-align: center;
    height: fit-content;
}

.related-services .services-container{
    position: relative;
    width: 80%;
    max-width: 960px;
    height: fit-content;
    margin: 0 auto;
    padding: 3.125rem 0;
    color: var(--mainblue);
    list-style: none;
}

.related-services .services-container .service{
    position: relative;
    width: 100%;
    height: fit-content;
    min-height: 21.875rem;
    margin: 20px auto;
}

.related-services .services-container .service:last-child{
    margin-top: 3.125rem;
    width: 70%;
}


.related-services .services-container .service h1{
    color: #b79275;
    font-size: 2.3rem;
    font-weight: normal;
}

.related-services .services-container .service ul{
    font-family: 'GenJyuuGothic';
    font-size: 1.1rem;
    line-height: 1.6;
}

.related-services .services-container .service:first-child::before{
    content: " ";
    display: block;
    position: relative;
    float: right;
    width: 25rem;
    height: 25rem;
    background-image: url('../img/Pages/7\ 相關服務/在線文字輔導_400x400.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.related-services .services-container .service:nth-child(2)::before{
    content: " ";
    display: block;
    position: relative;
    float: left;
    width: 21.875rem;
    height: 25rem;
    background-image: url('../img/Pages/7\ 相關服務/熱線服務_350x350.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.related-services .services-container .service:nth-child(3)::before{
    content: " ";
    display: block;
    position: relative;
    float: right;
    width: 25rem;
    height: 25rem;
    background-image: url('../img/Pages/7\ 相關服務/電郵輔導_400x400.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

@media screen and (max-width: 992px){
    .related-services .services-container .service:last-child{
        margin-top: 1.25rem;
        width: 80%;
    }
}

@media screen and (max-width: 768px){

    .related-services .services-container .service:nth-child(n):before{
        width: 12.5rem;
        height: 13.875rem;
        margin: 1.541rem auto;
        float: none;
    }

    .related-services .services-container .service:nth-child(2n+1):before{
        transform: translateX(2.3rem);
    }

    .related-services .services-container .service:nth-child(2n):before{
        transform: translateX(-2.3rem);
    }

    .related-services .services-container .service{
        width: fit-content;
    }

    .related-services .services-container .service h1{
        position: absolute;
        top: -1.541rem;
        width: 2.3rem;
    }

    .related-services .services-container .service:nth-child(1) h1{
        left: calc((100% - 12.5rem) / 2 - 2.3rem);
        transform: translateX(2.3rem);
    }

    .related-services .services-container .service:nth-child(2) h1{
        top: 0.7715rem;
        left: calc(12.5rem + ((100% - 12.5rem) / 2));
        transform: translateX(-2.3rem);
    }

    .related-services .services-container .service:nth-child(3) h1{
        left: calc((100% - 12.5rem) / 2 - 2.3rem);
        top: 0.7715rem;
        transform: translateX(2.3rem);
    }

    .related-services .services-container .service ul{
        width: fit-content;
    }
}

@media screen and (max-width: 576px){
    .related-services .services-container{
        width: 100%;
    }

    .related-services .services-container .service ul{
        padding-right: 1.5rem;
    }
}