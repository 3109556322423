nav.nav-container {
    top: 0;
    width: calc(100% - 2.5rem);
    height: 5rem;
    padding: 1.25rem 1.25rem 0 1.25rem;
}

nav.nav-container a.nav-logo{
    position: relative;
    display: inline-flex;
    float: left;
    text-decoration: none;
    width: fit-content;
    height: 5rem;
    align-items: center;
    margin: -0.9375rem 0 0 0;
}

nav.nav-container .nav-logo .front{
    position: relative;
    width: 5rem;
    height: 5rem;
    margin-left: 0.3125rem;
}

nav.nav-container .nav-logo::after{
    content: " ";
    display: block;
    position: absolute;
    width: 3.75rem;
    height: 3.75rem;
    top: 0.625rem;
    left: 0;
    background-image: url('../img/header/flicker-box_60x60.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}

nav.nav-container .nav-name{
    position: relative;
    display: inline-block;
    font-size: 2rem;
    width: fit-content;
    color: var(--mainblue);
    margin: 0;
}

nav.nav-container .nav-items-container{
    float: right;
    list-style: none;
    margin: 0;
    padding: 0;
}

nav.nav-container .nav-items-container .nav-item{
    float: right;
    text-align: center;
    margin-left: 0.625rem;
}

@keyframes jiggle{
    0%{
        transform: rotate(5deg);
    }
    0.1667%{
        transform: rotate(-5deg);
    }
    0.3333%{
        transform: rotate(5deg);
    }
    0.5%{
        transform: rotate(-5deg);
    }
    0.6667%{
        transform: rotate(5deg);
    }
    0.8333%{
        transform: rotate(-5deg);
    }
    1%{
        transform: rotate(5deg);
    }
    1.1667%{
        transform: rotate(-5deg);
    }
    1.3333%{
        transform: rotate(5deg);
    }
    1.5%{
        transform: rotate(-5deg);
    }
    1.6667%{
        transform: rotate(5deg);
    }
    1.8333%{
        transform: rotate(-5deg);
    }
    2%{
        transform: rotate(5deg);
    }
    2.1667%{
        transform: rotate(-5deg);
    }
    2.3333%{
        transform: rotate(-5deg);
    }
    2.5%{
        transform: rotate(-5deg);
    }
    2.6667%{
        transform: rotate(5deg);
    }
    2.8333%{
        transform: rotate(-5deg);
    }
    3%{
        transform: rotate(5deg);
    }
    3.1667%{
        transform: rotate(-5deg);
    }
    3.3333%{
        transform: rotate(5deg);
    }
    3.5%{
        transform: rotate(-5deg);
    }
    3.6667%{
        transform: rotate(5deg);
    }
    3.8333%{
        transform: rotate(-5deg);
    }
    4%{
        transform: rotate(5deg);
    }
    4.1667%{
        transform: rotate(-5deg);
    }
    4.3333%{
        transform: rotate(5deg);
    }
    4.5%{
        transform: rotate(-5deg);
    }
    4.6667%{
        transform: rotate(5deg);
    }
    4.8333%{
        transform: rotate(-5deg);
    }
    5%{
        transform: rotate(5deg);
    }
    5.0001%, 100%{
        transform: rotate(0deg);
    }
}

nav.nav-container .nav-item .phone-btn{
    display: block;
    width: fit-content;
    height: fit-content;
    padding: 1.25rem 0;
    color: var(--mainblue);
    font-size: 1.25rem;
    font-family: 'GenJyuuGothic';
    text-decoration: none;
    line-height: 1;
}

nav.nav-container .nav-item .phone-btn svg{
    color: white;
    background-color: #E0C193;
    height: 0.75rem !important;
    padding: 0.25rem;
    border-radius: 50%;
}

nav.nav-container .nav-item .donation-btn{
    display: block;
    width: fit-content;
    height: fit-content;
    padding: 0.3rem 1rem;
    margin-top: 0.575rem;
    background-color: #D88C57;
    color: white;
    font-size: 1.5rem;
    font-family: 'GenJyuuGothic';
    text-decoration: none;
    border-radius: 0.5rem;
}

nav.nav-container .nav-item .chat-btn{
    display: block;
    width: 5.5rem;
    height: 3.75rem;
    background-image: url('../img/header/chat-button_96x65.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: jiggle 20s normal infinite forwards;
}

nav.nav-container .nav-item.mobile a{
    position: relative;
    display: none;
    width: fit-content;
    height: fit-content;
    font-size: 2rem;
    padding: 0.875rem 0;
    text-decoration: none;
    color: var(--mainblue);
}

@media screen and (max-width: 576px){
    nav.nav-container .nav-item .donation-btn{
        font-size: 1rem;
        padding: 0.3rem 0.5rem;
        margin-top: 0.825rem;
    }

    nav.nav-container .nav-item .chat-btn{
        width: 3.65rem;
        height: 2.5rem;
        padding: 0.625rem 0;
    }

    nav.nav-container .nav-item.mobile a{
        display: block;
    }

    nav.nav-container .nav-name{
        display: none;
    }
}
