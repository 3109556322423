.about-us{
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: fit-content;
}

.principles-container, .how-to-use-container{
    position: relative;
    width: 80%;
    max-width: 960px;
    margin: 0 auto;
    padding: 3.125rem 0;
    color: var(--mainblue);
}

/* Main text and logo in About Us styles */

.about-us .main-text{
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    position: relative;
    width: 75%;
    max-width: 960px;
    height: fit-content;
    margin: 0 auto;
    padding-top: 6.25rem;
    color: var(--mainblue);
}

.about-us .main-text h1{
    position: absolute;
    top: 0;
    left: calc(50% - 5rem);
    font-size: 2.5rem;
    font-weight: normal;
    text-align: center;
    background-image: url('../img/Pages/2\ 關於我們/a_140x40.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

}

.about-us .main-text p{
    font-size: 1.3rem;
    font-family: 'GenJyuuGothic';
    line-height: 1.6;
    margin: 0 auto;
    text-align: start;
    height: fit-content;
}

.about-us .main-text .logo{
    height: 15.625rem;
}

@media screen and (max-width: 992px){
    .about-us .main-text{
        flex-direction: column;
        align-items: center;
        row-gap: 1.5rem;
    }
}

/* 4 Principles styles */
.principles-container .sub-title{
    font-size: 2.5rem;
    font-weight: normal;
}

.principles-container .principles-list{
    margin:0 auto;
    width: 70%;
    list-style: none;
    padding: 0;
}

.principles-list .principle{
    width: 100%;
    height: fit-content;
    display: inline-grid;
    column-gap: 1.25rem;
    grid-template-rows: auto auto;
    align-content: center;
}

.principles-list .principle img{
    height: 6.25rem;
    grid-row: 1 / span 2;
}

.principles-list .principle p{
    font-family: 'GenJyuuGothic';
    margin-top: 0;
    margin-bottom: 0;
    width: fit-content;
    height: fit-content;
}

.principles-list .principle .name{
    font-size: 1.3rem;
    color: var(--mainblue);
    border-radius: 5px;
    padding: 0 15px;
    margin-top: 15px;
}

.principles-list .principle:nth-child(2n+1){
    grid-template-columns: min-content auto;
}

.principles-list .principle:nth-child(2n+0){
    grid-template-columns: auto min-content;
}

.principles-list .principle:nth-child(2n+1) img{
    grid-column: 1 / span 1;
}

.principles-list .principle:nth-child(2n+0) img{
    grid-column: 2 / span 1;
}

.principles-list .principle:nth-child(2n+1) p{
    justify-self: start;
}

.principles-list .principle:nth-child(2n+0) p{
    justify-self: end;
}

@media screen and (max-width: 992px){
    .principles-container .principles-list{
        width: 80%;
    }
}

@media screen and (max-width: 768px){
    .principles-container .principles-list{
        width: 90%;
    }
}

@media screen and (max-width: 576px){
    .about-us .principles-container .principles-list{
        width: 100%;
        padding: 0;
    }

    .about-us .principles-container .sub-title{
        text-align: center;
    }
}

/* How to use section styles */
.about-us .how-to-use-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 4.125rem;
}

.how-to-use-container .sub-title{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0.8125rem 0;
    font-size: 2.5rem;
    font-weight: normal;
}

.how-to-use-container .use-cases-list{
    list-style: none;
    width: 35%;
    font-family: 'GenJyuuGothic';
    font-size: 1.5rem;
    text-align: end;
    margin: 0;
    padding-left: 0;
    line-height: 1.6;
}

.how-to-use-container .use-cases-list li:first-child{
    color: rgb(56, 56, 56);
}


.how-to-use-container .use-cases-list li:nth-child(2){
    color: rgb(78, 129, 151);
}

.how-to-use-container .use-cases-list li:nth-child(3){
    color: rgb(183, 100, 53);
}

.how-to-use-container .methods-container{
    font-family: 'GenJyuuGothic';
    color: var(--mainblue);
    width: 45%;
    padding-right: 10%;
    line-height: 1.6;
}

.how-to-use-container .methods-container p{
    margin: 0;
}

.how-to-use-container .methods-container .methods-list{
    list-style:square;
    margin: 0;
}

.how-to-use-container .methods-container .methods-list span{
    color: #e19251;
}

.how-to-use-container .methods-container a{
    color: var(--mainblue);
}

@media screen and (max-width: 768px){
    .about-us .how-to-use-container .methods-container{
        padding-right: 0;
    }
}

@media screen and (max-width: 576px){
    .about-us .how-to-use-container{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 3.125rem;
    }

    .about-us .how-to-use-container .sub-title{
        position: relative;
        text-align: center;
        margin: 1.5rem 0;
    }

    .about-us .how-to-use-container .methods-container{
        width: 100%;
    }

    .about-us .how-to-use-container .use-cases-list{
        position: relative;
        padding-right: 40%;
        width: 60%;
    }

    .about-us .how-to-use-container .use-cases-list::after{
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        right: calc(40% - 5rem);
        width: 5rem;
        height: 5rem;
        background-image: url('../img/Pages/1\ 主頁/k_63x70.gif');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}