.volunteer-recruit{
    position: relative;
    width: 100vw;
    height: fit-content;
    overflow: hidden;
}

/* Main title and text on Volunteer Recruit page styles */

.volunteer-recruit .main-text{
    position: relative;
    width: 80%;
    max-width: 960px;
    height: fit-content;
    margin: 0 auto;
    color: var(--mainblue);
}

.volunteer-recruit .main-text h1{
    margin: 0 auto;
    width: fit-content;
    height: fit-content;
    font-size: 2.5rem;
    font-weight: normal;
    background-image: url('../img/Pages/4\ 義工招募/a_140x40.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.volunteer-recruit .main-text p{
    margin: 50px auto;
    font-size: 1.3rem;
    font-family: 'GenJyuuGothic';
    line-height: 1.6;
    text-align: center;
    height: fit-content;
    width: 80%;
}

/* Apply deadline styles */

.volunteer-recruit .apply-deadline-container{
    position: relative;
    width: 80%;
    max-width: 960px;
    height: fit-content;
    margin: 0 auto;
    padding: 50px 0;
    color: #4e8197;
}

.apply-deadline-container h1{
    position: relative;
    margin: 20px auto;
    padding: 5px 2.1rem;
    width: fit-content;
    height: fit-content;
    background-color: rgba(153, 209, 196, 0.5);
    border-radius: 5px;
    font-size: 2.1rem;
    font-weight: normal;
}

.apply-deadline-container h1::before{
    content: " ";
    display: block;
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: -4.5rem;
    top: -2.5rem;
    transform: rotate(210deg);
    background-image: url('../img/Pages/4\ 義工招募/b_63x70.gif');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.apply-deadline-container a:hover{
    background-color: rgba(0,0,0,0.05);
}

.apply-deadline-container a{
    display: block;
    text-decoration: none;
    width: fit-content;
    height: fit-content;
    margin: 30px auto;
    padding: 3px 5rem;
    border-radius: 5px;
    font-size: 1.5rem;
    color: #4e8197;
    background-color: #ffe380;
}

/* Responsibility text styles */

.volunteer-recruit .responsibility-container{
    position: relative;
    width: 80%;
    max-width: 960px;
    height: fit-content;
    margin: 0 auto 0.625rem auto;
    padding: 3.125rem 0;
    line-height: 1.6;
    color: var(--mainblue);
}

.responsibility-container .sub-title{
    margin: 0 0 1.5625rem -1.5625rem;
    font-size: 2.3rem;
    font-weight: normal;
    color: #7d7767;
}

.responsibility-container p,
.responsibility-container ul{
    font-family: 'GenJyuuGothic';
    font-size: 1.1rem;
}

.responsibility-container ul span{
    color: #b79275;
}

.responsibility-container p span{
    color: #dd2e44;
}

.responsibility-container img{
    position: absolute;
    right: 0.9375rem;
    bottom: -5%;
    width: 17.5%;
    max-width: 9.375rem;
}

.responsibility-container::before{
    content: " ";
    display:block;
    position: absolute;
    right: calc(min(17.5%, 9.375rem) + 3%);
    bottom: -5%;
    width: 17.5%;
    max-width: 9.375rem;
    height: 25%;
    max-height: 5.625rem;
    z-index: 1;
    background-image: url('../img/Pages/4\ 義工招募/f_150x89.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

}

.responsibility-container::after{
    content: " ";
    display:block;
    position: absolute;
    right: calc(min(17.5%, 9.375rem) - 5.5%);
    bottom: -13.33%;
    width: 17.5%;
    max-width: 9.375rem;
    height: 25%;
    max-height: 5.625rem;
    opacity: 0.7;
    z-index: 1;
    background-image: url('../img/Pages/4\ 義工招募/g_139x90.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

}

@media screen and (max-width: 768px){
    .volunteer-recruit .responsibility-container{
        width: 70%;
    }

    .volunteer-recruit .responsibility-container .sub-title{
        margin-left: 0;
    }

    .volunteer-recruit .responsibility-container img{
        width: 6rem;
        top: 0;
    }

    .volunteer-recruit .responsibility-container::before,
    .volunteer-recruit .responsibility-container::after{
        display: none;
    }
}

/* Requirements text styles */

.volunteer-recruit .requirements-container{
    position: relative;
    width: calc(75% - 18.75rem);
    max-width: 960px;
    height: fit-content;
    margin: 0 auto 0.625rem auto;
    padding: 3.125rem 0 3.125rem 18.75rem;
    line-height: 1.6;
    color: var(--mainblue);
}

.volunteer-recruit .requirements-container::before{
    content: " ";
    display: block;
    position: absolute;
    left: -18.75rem;
    width: 37.5rem;
    height: 15.625rem;
    background-image: url('../img/Pages/4\ 義工招募/h_500x250.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.requirements-container .sub-title{
    margin: 0 0 25px -25px;
    font-size: 2.3rem;
    font-weight: normal;
    color: #416c7c;
}

.requirements-container p,
.requirements-container ul{
    font-family: 'GenJyuuGothic';
    font-size: 1.1rem;
}

.requirements-container ul span{
    color: #547bbd;
}

@media screen and (max-width: 768px){
    .volunteer-recruit .requirements-container{
        padding-top: 0;
    }

    .volunteer-recruit .requirements-container .sub-title{
        margin-left: 0;
    }

    .volunteer-recruit .requirements-container{
        width: calc(75% - 11.375rem);
        padding: 3.125rem 2rem 3.125rem 9.375rem;
    }

    .volunteer-recruit .requirements-container::before{
        transform: translateX(-9.375rem);
    }
}

@media screen and (max-width: 576px){
    .volunteer-recruit .requirements-container{
        width: 80%;
        padding: 3.125rem 0;
    }

    .volunteer-recruit .requirements-container::before{
        transform: translateX(0);
        left: calc(100% - 13.125rem);
        top: -5rem;
        height: 11rem;
        width: 28.125rem;
    }
}

/* Benefits text styles */

.volunteer-recruit .benefits-container{
    position: relative;
    width: 75%;
    max-width: 960px;
    height: fit-content;
    margin: 0 auto 0.625rem auto;
    padding: 3.125rem 0;
    line-height: 1.6;
    color: var(--mainblue);
}

.benefits-container .sub-title{
    margin: 0 0 25px -25px;
    font-size: 2.3rem;
    font-weight: normal;
    color: #8d8d8d;
}

.benefits-container p,
.benefits-container ul{
    font-family: 'GenJyuuGothic';
    font-size: 1.1rem;
}

.benefits-container ul{
    padding-inline-start: 1.5rem;
}

.benefits-container span{
    color: #7d7767;
}

.volunteer-recruit .benefits-container::after{
    content: " ";
    display: block;
    position: relative;
    top: 2rem;
    left: 50%;
    width: 37.5rem;
    height: 2.1875rem;
    background-image: url('../img/Pages/4\ 義工招募/i_600x35.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
}

@media screen and (max-width: 768px){
    .volunteer-recruit .benefits-container{
        padding-right: 2.5rem;
    }
    
    .volunteer-recruit .benefits-container .sub-title{
        margin-left: 0;
    }
}

@media screen and (max-width: 576px){
    .volunteer-recruit .benefits-container{
        padding-right: 0;
    }

    .volunteer-recruit .benefits-container::before{
        content: " ";
        display: block;
        position: absolute;
        right: -15rem;
        top: -5rem;
        height: 11rem;
        width: 28.125rem;
        background-image: url('../img/Pages/4\ 義工招募/l_352x176.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .volunteer-recruit .benefits-container::after{
        display: none;
    }
}

/* Common questions and answers styles */

.volunteer-recruit .common-questions-container{
    position: relative;
    width: 75%;
    max-width: 960px;
    height: fit-content;
    margin: 0 auto 0.625rem auto;
    padding: 3.125rem 0;
    line-height: 1.6;
    color: var(--mainblue);
}

.common-questions-container .sub-title{
    margin: 0 0 1.5625rem 7.8125rem;
    font-size: 2.3rem;
    font-weight: normal;
    color: #b79275;
}

.common-questions-container ol{
    font-family: 'GenJyuuGothic';
    font-size: 1.1rem;
}

.common-questions-container > ol{
    margin-left: 9.375rem;
}

.common-questions-container::before{
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 11.675rem;
    width: 9.375rem;
    height: 11.375rem;
    background-image: url('../img/Pages/4\ 義工招募/k_150x181.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.common-questions-container p{
    margin: 0.3rem 0;
}

.common-questions-container .question{
    color: #b79275;
}

.common-questions-container .answer{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.common-questions-container .answer.expanded{
    max-height: 300px;
}

.common-questions-container .question button{
    display: inline-block;
    border-style: none;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    font-size: 0.6rem;
    padding: 0;
    margin: 0 5px;
    color: white;
    background-color: #d9d9d9;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    transform: translateY(-0.15rem) rotate(0deg);
    transition: transform 0.5s;
}

.common-questions-container .question button.expanded{
    transform: translateY(-0.15rem) rotate(180deg);
}

.common-questions-container p span{
    color: #dd2e44;
}

@media screen and (max-width: 768px){
    .volunteer-recruit .common-questions-container{
        padding-right: 2.5rem;
    }

    .volunteer-recruit .common-questions-container::before{
        top: -2rem;
        left: calc(100% - 12.375rem);
    }

    .volunteer-recruit .common-questions-container .sub-title{
        margin-left: 0;
    }

    .volunteer-recruit .common-questions-container > ol{
        padding-inline-start: 1.1rem;
        margin-left: 0;
    }
}

@media screen and (max-width: 576px) {
    .volunteer-recruit .common-questions-container{
        padding-right: 0;
    }

    .volunteer-recruit .common-questions-container::before{
        left: calc(100% - 9.375rem);
    }
}

.volunteer-recruit .contact-text{
    position: relative;
    width: 60%;
    max-width: 960px;
    margin: 0 auto 50px auto;
    font-size: 1rem;
    font-family: 'GenJyuuGothic';
    color: var(--mainblue);
    line-height: 1.6;
    text-align: center;
}

.volunteer-recruit .contact-text a{
    color: var(--mainblue);
}