.modal-container{
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 50000;
}

.modal-container.opened{
    display: block;
}

.modal-container .popup-modal{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 80%;
    height: 80%;
    border-radius: 1rem;
    border: 0.1rem solid grey;
    background-color: white;
}

.modal-container .popup-modal .close-btn{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1rem;
    color: grey;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    border-style: none;
    cursor: pointer;
    background-color: transparent;
    z-index: 1;
}

.modal-container .popup-modal .close-btn span{
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    text-align: center;
}

.modal-container .popup-modal iframe{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    border-style: none;
}
