.donationSuccess{
    position: relative;
    width: 80%;
    max-width: 960px;
    height: fit-content;
    min-height: calc(100vh - 6.25rem - (200px + 2.5rem));
    margin: 0 auto;
    padding-bottom: 1rem;
    overflow: hidden;
    font-family: 'GenJyuuGothic';;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;

    --primarygreen: #4E8197;
    --secondarygreen: #99d1c48a;
}

.donationSuccess h2{
    color: var(--primarygreen);
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    margin: 0 auto;
}

.donationSuccess p{
    color: var(--mainblue);
    text-align: center;
    font-size: 1.3rem;
    margin: 0 auto;
}

.donationSuccess .backdrop{
    position: absolute;
    left: 50%;
    top: 10.7rem;
    transform: translateX(-50%);
    width: 30rem;
    height: 30rem;
    opacity: 0;
    z-index: 1;
}

.donationSuccess iframe{
    position: relative;
    display: block;
    margin: 0 auto;
    width: 30rem;
    height: 30rem;
}

@media screen and (max-width: 576px) {

    .donationSuccess iframe,
    .donationSuccess .backdrop{
        width: 18rem;
        height: 18rem;
    }
}

.donationSuccess a{
    display: block;
    width: fit-content;
    height: fit-content;
    border-radius: 0.5rem;
    padding: 0.3rem 3rem;
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    color: var(--primarygreen);
    background-color: var(--secondarygreen);
    cursor: pointer;
}