.home{
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: fit-content;
}

/* Carousel style */

.carousel{
    position: relative;
    max-height: 512px;
    max-width: 889px;
    width: 80%;
    margin: 0 auto;
}

.carousel .carousel-track-container{
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel-track-container .carousel-track{
    list-style: none;
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;

}

.carousel-track .carousel-slide{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 0.5s ease-in;
}


.carousel-track .carousel-slide .carousel-image{
    width: 100%;
}

.carousel-track .carousel-slide p{
    position: absolute;
    bottom: 18%;
    width: 100%;
    font-size: 2.5rem;
    color: var(--mainblue);
    text-align: center;
    margin: 0;
}

.carousel-track .carousel-slide .main-text{
    background-image: url('../img/Pages/1\ 主頁/b_140x40.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.carousel-track .carousel-slide:nth-child(3) .main-text img{
    display: inline-block;
    position:relative;
    top: 0.2rem;
    width: 2.4rem;
    height: 2.4rem;
}

.carousel-track .carousel-slide:nth-child(2) .main-text{
    background-image: url('../img/Pages/1\ 主頁/i_271x40.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.carousel-track .carousel-slide .super-text{
    font-size: 1.25rem;
    bottom: calc(18% + 45px);
}

@media screen and (max-width: 640px){
    .carousel-track .carousel-slide .main-text{
        font-size: 2rem;
    }

    .carousel-track .carousel-slide:nth-child(3) .main-text img{
        top: 0.2rem;
        width: 1.9rem;
        height: 1.9rem;
    }

    .carousel-track .carousel-slide .super-text{
        font-size: 1rem;
        bottom: calc(18% + (2rem * 1.125));
    }
}


.carousel-nav{
    position: relative;
    top: 0;
    height: fit-content;
    display: flex;
    justify-content: center;
}

.carousel-nav .carousel-indicator{
    width: 12px;
    height: 12px;
    padding: 0;
    border-radius: 50%;
    border-style: none;
    background: rgba(0,0,0,0.3);
    margin: 0 5px;
    cursor: pointer;
}

.carousel-nav .carousel-indicator.selected{
    background: rgb(187, 50, 0);
}

@media screen and (max-width: 576px){
    .carousel{
        max-width: 576px;
        width: 100%;
    }
}

/* Descriptions at the middle of homepage styles */
.descriptions-container{
    position: relative;
    margin-top: 50px;
    width: 100%;
    height: 400px;  
    font-family: 'GenJyuuGothic';
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--mainblue);
}

.descriptions-container::after{
    content:" ";
    display:block;
    position: absolute;
    top: 0;
    right: -178px;
    height: 20px;
    width: 533.5px;
    background-image: url('../img/Pages/1\ 主頁/c_1067x20.png');
    background-repeat: no-repeat;
    background-size:200%;
}

.descriptions-container::before{
    content: " ";
    display: block;
    position: absolute;
    top: 50px;
    left: -100px;
    width: 31.25rem;
    height: 15.625rem;
    background-image: url('../img/Pages/1\ 主頁/e_500x250.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.descriptions-container .dialog1{
    position: absolute;
    top: 2.5rem;
    left: 20rem;
    width: 10rem;
    height: 6.25rem;
    padding: 0 1.25rem;
    display: flex;
    flex-direction: column;
    background-image: url('../img/Pages/1\ 主頁/d_154x100.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.descriptions-container .dialog1 p{
    margin: 0;

}

.descriptions-container .dialog1 p:first-child{
    padding-top: 12px;
    margin-bottom: -0.5rem;
    padding-left: 20%;
}

.descriptions-container .dialog1 p:nth-of-type(2){
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 28%;
}

.descriptions-container .dialog2{
    display: flex;
    position: absolute;
    top: 5.625rem;
    left: 29.375rem;
    background-image: url('../img/Pages/1\ 主頁/f_160x95.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    width: 10rem;
    height: 6.25rem;
    justify-content: center;
    align-items:center;
    margin: 0;
}

.descriptions-container .dialog2 p{
    margin: 0;
    margin-top: -0.625rem;
}

.descriptions-container .dialog3{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:flex-start;
    position: absolute;
    top: 1.5625rem;
    left: 38.125rem;
    width: 19rem;
    height: 11.25rem;
    background-image: url('../img/Pages/1\ 主頁/j_304x164.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 1rem;

}

.descriptions-container .dialog3 p{
    margin: 0;
    margin-left: 2.25rem;
}

.descriptions-container .dialog3::after{
    content: " ";
    display: block;
    position: absolute;
    top: 20%;
    left: 85%;
    width: 4.375rem;
    height: 4.375rem;
    background-image: url('../img/Pages/1\ 主頁/k_63x70.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

@media screen and (max-width: 992px){

    .descriptions-container .dialog1{
        left: calc(50vw - 9.38rem);
    }

    .descriptions-container .dialog2{
        left: calc(50vw );
    }

    .descriptions-container .dialog3{
        left: calc(50vw - 9.38rem);
        top: 12.5rem;
    }
}

@media screen and (max-width: 768px){
    .descriptions-container::before{
        left: max(calc(-6.25rem - (768px - 100vw)), -12.5rem);
    }

    .descriptions-container::after{
        display: none;
    }
}

@media screen and (max-width: 576px){
    .descriptions-container{
        height: calc(400px + 15.625rem);
    }

    .descriptions-container .dialog1{
        top: 18.125rem;
    }

    .descriptions-container .dialog2{
        top: 21.25rem;
    }

    .descriptions-container .dialog3{
        top: 28.125rem;
    }
}

/* Learn More styles */
.learn-more-container{
    position: relative;
    height: fit-content;
    max-width: 889px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 100px;
}

.learn-more-container .sub-title{
    font-size: 2.5rem;
    font-weight: normal;
    color: var(--mainblue);
}

.learn-more-container .learn-more-links{
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    width: 100%;
    height: 100%;
}

.learn-more-links .link{
    text-decoration: none;
}

.learn-more-links .link-image{
    width: 9.375rem;
    margin: 0 auto;
}

.learn-more-links .link-text{
    margin: 0 auto;
    text-align: center;
    color: var(--mainblue);
    font-family: 'GenJyuuGothic';
    font-size: 1.25rem;
    background-image: url('../img/Pages/1\ 主頁/i_150x22.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

@media screen and (max-width: 576px){
    .learn-more-container .sub-title{
        text-align: center;
    }
}

@media screen and (max-width: 768px){
    .learn-more-container .learn-more-links{
        display: grid;
        grid-template-columns: 24vw 24vw 24vw;
        grid-template-rows: 50% 50%;
        column-gap: 0px;
    }

    .learn-more-container .learn-more-links .link .link-image{
        width: 24vw;
    }

    .learn-more-container .learn-more-links .link:first-child{
        grid-column: 2 / span 1;
        grid-row: 1 /span 1;
    }

    .learn-more-container .learn-more-links .link:nth-child(2){
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
    }

    .learn-more-container .learn-more-links .link:last-child{
        grid-column: 3 / span 1;
        grid-row: 2 / span 1;
    }
}
