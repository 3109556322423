.chatroom{
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: fit-content;
}

.chatroom .main-text{
    position: relative;
    width: 80%;
    max-width: 960px;
    height: fit-content;
    margin: 0 auto;
    color: var(--mainblue);
}

.chatroom .main-text h1{
    width: fit-content;
    height: fit-content;
    margin: 3.125rem auto;
    font-size: 2.5rem;
    font-weight: normal;
    background-image: url('../img/Pages/7\ 相關服務/a_140x40.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.chatroom .main-text p{
    margin: 50px auto;
    font-size: 1.3rem;
    font-family: 'GenJyuuGothic';
    line-height: 1.6;
    text-align: center;
    height: fit-content;
}

.chatroom .main-text p span::before{
    content: "\A";
    display: block;
}

@media screen and (max-width: 850px){
    .chatroom .main-text p span::before{
        content:" ";
        display: none;
    }
}

.chatroom .t-and-c-container{
    position: relative;
    width: 80%;
    max-width: 960px;
    height: fit-content;
    margin: 0 auto 6.5rem auto;
    padding: 1rem 2rem;
    color: var(--mainblue);
    background-color: #ECE8DF;
    border-radius: 1rem;
    font-family: 'GenJyuuGothic';
}

.chatroom .t-and-c-container .toggle-lang-container{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: end;
    column-gap: 0.5rem;
}

.chatroom .t-and-c-container .toggle-lang-container p{
    width: 100%;
    height: fit-content;
    text-align: start;
}

.chatroom .t-and-c-container .toggle-lang-container .toggle-lang-btn{
    height: fit-content;
    width: fit-content;
    min-width: 11rem;
    background-color: var(--mainblue);
    color: white;
    border-style: none;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    font-family: 'GenJyuuGothic';
    cursor: pointer;
    align-self: center;
}

.chatroom .t-and-c-container .toggle-lang-btn:hover{
    background-color: white;
    color: var(--mainblue);
    border: 0.1rem solid var(--mainblue);
}

.chatroom .t-and-c-container a{
    color: var(--mainblue);
}

.chatroom .t-and-c-container label{
    font-weight: 500;
}

.chatroom .t-and-c-container .start-chat-btn{
    position: absolute;
    left: 50%;
    bottom: -3rem;
    transform: translateX(-50%);
    background-color: #99D1C4;
    border-style: none;
    border-radius: 0.3rem;
    font-size: 1.3rem;
    padding: 0.2rem 2rem;
    cursor: pointer;
}

.chatroom .t-and-c-container .start-chat-btn:disabled{
    background-color: lightgrey;
    color: grey;
    cursor: default;
}

.chatroom .chat-container{
    position: relative;
    width: 80%;
    max-width: 960px;
    height: fit-content;
    margin: 0 auto;
    color: var(--mainblue);
    font-family: "GenJyuuGothic";
    font-size: 1rem;
}

@media screen and (max-width: 576px){
    .chatroom .chat-container{
        width: 90%;
    }
}

.chatroom .chat-container aside.emoji-picker-react{
    position: absolute !important;
    left: 0.5rem !important;
    bottom: 25% !important;
}

.chatroom .chat-container #endchat-btn{
    position: absolute;
    left: calc(100% - 3rem);
    width: 3rem;
    height: 3rem;
    border-style: none;
    background-color: transparent;
    color: grey;
    cursor: pointer;
    z-index: 5;
}

.chatroom .chat-container #endchat-btn span{
    font-size: 2rem !important;
}

.chatroom .chat-container .messages-container{
    position: relative;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    width: calc(100% - 1.5rem);
    height: 27rem;
    padding: 0.5rem 0.75rem 2.5rem 0.75rem;
    background-color: rgba(0,0,0,0.05);
    border-radius: 1rem;
}

.chatroom .chat-container .messages-container .loader{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}

.chatroom .chat-container .messages-container .loader .spinning-circle{
    position: absolute;
    left: calc(50% - 3rem);
    top: calc(50% - 3rem);
    width: 5rem;
    height: 5rem;
    display: block;
    border: 1rem solid darkgrey;
    border-top: 1rem solid lightblue;
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
}

.chatroom .chat-container .messages-container .loader .queuing-text,
.chatroom .chat-container .messages-container .loader .connecting-text{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-45%) translateY(4rem); 
    text-align:center;
}

@keyframes spin {
    0% { transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

.chatroom .chat-container .messages-container .message{
    width: fit-content;
    height: fit-content;
    max-width: 60%;
    margin: 0.1rem 0;
    padding: 0.5rem;
    border-radius: 1rem;
    overflow-wrap: break-word;
}

.chatroom .chat-container .messages-container .message span::before{
    content: "\A";
    display: block;
}

.chatroom .chat-container .messages-container .message span{
    display: block;
    font-size: 0.5rem;
    color: rgba(0,0,0,0.5);
}

.chatroom .chat-container .messages-container .message.right span{
    text-align: end;
}

.chatroom .chat-container .messages-container .message.left span{
    text-align: start;
}

.chatroom .chat-container .messages-container .message.right{
    background-color: #d88c57;
    align-self: flex-end;
}

.chatroom .chat-container .messages-container .message.left{
    background-color: lightgrey;
    align-self: flex-start;
}

.chatroom .chat-container .messages-container .message.special{
    background-color: darkgrey;
    color: white;
    align-self: center;
}

.chatroom .chat-container .messages-container .message.special span{
    display: none;
}

.chatroom .chat-container .input-container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    margin: 1rem 0;
}

.chatroom .chat-container .input-container .typing-msg{
    position: absolute;
    width: fit-content;
    height: fit-content;
    margin: 0;
    top: -3.5rem;
    left: 0;
    padding: 0.5rem 1rem;
    color: grey;
}

.chatroom .chat-container .input-container #msg-input{
    width: calc(100% - 3.5rem);
    font-size: 1rem;
    border-style: none;
    background-color: rgba(0,0,0,0.05);
    border-radius: 2rem;
    padding: 0 1.5rem;
}

.chatroom .chat-container .input-container #msg-input:focus,
.chatroom .chat-container .input-container #submit-btn:focus,
.chatroom .chat-container .input-container #emoji-btn:focus{
    outline:none;
}

.chatroom .chat-container .input-container #submit-btn,
.chatroom .chat-container .input-container #emoji-btn{
    width: 3rem;
    height: 3rem;
    border-style: none;
    background-color: transparent;
    color: grey;
    cursor: pointer;
}

.chatroom .chat-container .input-container #submit-btn span,
.chatroom .chat-container .input-container #emoji-btn span{
    font-size: 2rem !important;
}



