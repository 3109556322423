/* unopened menu button style  */
.menu-btn{
    display: block;
    position: fixed;
    top: calc(50% - 250px);
    right: 0;
    width: 82px;
    height: 500px;
    z-index: 1;
    transform-origin: 100% 50%;
    transition: transform 0.5s ease-in-out;
}

.menu-btn.hide-btn{
    transform: scaleX(1);
}

@media (hover: hover){
    .menu-btn.hide-btn{
        transform: scaleX(0);
    }
}


.menu-btn img:not(.background){
    position: absolute;
    width: 30px;
    height: 30px;
    top: calc(50% - 15px);
    left: 32px;
    z-index: 1;
}

.menu-btn img.background{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 576px){
    .menu-btn{
        display: none;
    }
}


/* Opened side menu style */

@keyframes slidein{
    0% {
        transform: translateX(0);
    }
    1%{
        transform: translateX(calc(-50vw + 100vh));
    }
    100%{
        transform: translateX(calc(-150vw - 100vh));
    }
}

@keyframes slideout{
    0% {
        transform: translateX(calc(-150vw - 100vh));
    }
    99%{
        transform: translateX(calc(-50vw + 100vh));
    }
    100%{
        transform: translateX(0);
    }
}

@keyframes squishin{
    0% {
        transform: rotate(-90deg);
    }
    16.67%{
        transform: rotate(-90deg);
    }
    58.33%{
        transform: rotate(-90deg) scaleY(2) translateY(-5.6vh);
    }
    100% {
        transform: rotate(-90deg);
    }
}

@keyframes squishout{
    0% {
        transform: rotate(-90deg);
    }
    41.67%{
        transform: rotate(-90deg) scaleY(2) translateY(-5.6vh);
    }
    83.33%{
        transform: rotate(-90deg);
    }
    100%{
        transform: rotate(-90deg);
    }
}


.menu-content.opened{
    animation: slidein 0s forwards ease-out;
}

.menu-content:not(.opened){
    animation: slideout 0s forwards ease-in;
}

.menu-content{
    position: fixed;
    top: 0;
    left: calc(150vw + 100vh);
    z-index: 2;
    background-color: var(--mainblue);
    width: calc(100vw - (100vw - 100%));
    height: 100vh;
}

.menu-content.opened svg.menu-content-wave{
    animation: squishin 1.2s none ease-in-out;
}

.menu-content:not(.opened) svg.menu-content-wave{
    animation: squishout 1.2s none ease-in-out;
}

svg.menu-content-wave{
    width: 100vh;
    height: 100%;
    position: absolute;
    top: 0;
    left: -60.5vh;
    transform: rotate(-90deg) ;
}

.menu-nav {
    top: 0;
    width: calc(100% - 2.5rem);
    height: 5rem;
    padding: 1.25rem 1.25rem 0 1.25rem;
    background-color: none;
}

.menu-nav a.nav-logo{
    position: relative;
    display: inline-flex;
    float: left;
    text-decoration: none;
    width: fit-content;
    height: 5rem;
    align-items: center;
    margin: -15px 0 0 0;
}

.menu-nav .nav-logo .front{
    position: relative;
    width: 5rem;
    height: 5rem;
    margin-left: 0.3125rem;
}

.menu-nav .nav-logo::after{
    content: " ";
    display: block;
    position: absolute;
    width: 3.75rem;
    height: 3.75rem;
    top: 0.625rem;
    left: 0;
    background-image: url('../img/header/flicker-box_60x60.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}

.menu-nav .nav-name{
    position: relative;
    display: inline-block;
    font-size: 2rem;
    width: fit-content;
    color: white;
    margin: 0;
}

@media screen and (max-width: 540px){
    .menu-nav .nav-name{
        display: none;
    }
}

.menu-content.opened .menu-nav .nav-items-container{
    opacity: 1;
    transform: translateY(0);
    transition: transform 1s ease-in-out 0.6s, opacity 1s ease-in-out 0.6s !important;
}

.menu-nav .nav-items-container{
    position: relative;
    float: right;
    list-style: none;
    margin: 0;
    padding: 0;
    opacity: 0;
    transform: translateY(1.875rem);
    transition: transform 1s linear 0s, opacity 1s linear 0s;
}

.menu-nav .nav-items-container .nav-item{
    float: right;
    text-align: center;
    margin-left: 0.625rem;
}

.menu-nav .nav-item.mobile a{
    position: relative;
    top: 0;
    display: none;
}

@media screen and (max-width: 576px){
    .menu-nav .nav-item.mobile a{
        display: block;
    }
}

.menu-nav .nav-item .phone-btn{
    display: block;
    width: fit-content;
    height: fit-content;
    padding: 1.25rem 0;
    color: white;
    font-size: 1.25rem;
    font-family: 'GenJyuuGothic';
    text-decoration: none;
    line-height: 1;
}

.menu-nav .nav-item .phone-btn svg{
    color: white;
    background-color: #E0C193;
    height: 0.75rem !important;
    padding: 0.25rem;
    border-radius: 50%;
}

.menu-nav .nav-item .donation-btn{
    display: block;
    width: fit-content;
    height: fit-content;
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
    margin-top: 0.825rem;
    background-color: #D88C57;
    color: white;
    font-family: 'GenJyuuGothic';
    text-decoration: none;
    border-radius: 0.5rem;
}

.menu-nav .nav-item .chat-btn{
    display: block;
    width: 5.5rem;
    height: 3.75rem;
    background-image: url('../img/menu\ bar/chat-button-slide-menu_88x60.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: jiggle 20s normal infinite forwards;
}

.menu-nav .nav-item.mobile a{
    width: fit-content;
    height: fit-content;
    font-size: 2rem;
    padding: 0.875rem 0;
    text-decoration: none;
    justify-self: start;
    color: white;
}

.menu-nav .nav-item:last-child{
    margin: 0;
}

.menu-content.opened .menu-btn{
    transform: scaleX(1);
    transition: transform 0.5s ease-in-out 1.6s !important;
}

.menu-content .menu-btn{
    position: absolute;
    display: block;
    top: calc(50% - 250px);
    right: 0;
    width: 82px;
    height: 500px;
    z-index: 1;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform 0.5s ease-in-out 0s;
}

.menu-content .menu-btn img:not(.background){
    width: 30px;
    position: absolute;
    right: 20px;
    top: calc(50% - 15px);
    z-index: 1;
}

.menu-content img.background{
    height: 500px;
}

@media screen and (max-width: 576px){
    .menu-nav .nav-item .chat-btn{
        width: 3.65rem;
        height: 2.5rem;
        padding: 0.625rem 0;
    }

    .menu-content .menu-btn{
        display: none;
    }
}

.menu-content.opened ul.menu-links{
    transform: translateX(0);
    opacity: 1;
    transition: transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s !important;
}

ul.menu-links{
    position: relative;
    height: fit-content;
    width: fit-content;
    padding: 20vh 10vw;
    margin: 0;
    font-size: 5rem;
    list-style-type: none;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    column-gap: 5%;
    transform: translateX(100px);
    opacity: 0;
    transition: transform 1s linear 0s, opacity 1s linear 0s;
}

.menu-links .link{
    width: 3em;
    height: 1em;
    padding: 0;
    margin: 0;
}

.menu-links .link a{
    text-decoration: none;
    color: white;
    font-size: 0.6em;

}

.menu-links .link a .fa-caret-down{
    margin: 0 5px;
}

.menu-links .link .mobile-dropdown-trigger{
    display: none;
    position: absolute;
    top: 1.8rem;
    right: -10px;
    margin: 0 10px;
    z-index: 2;
}

@media screen and (max-width: 610px){
    ul.menu-links{
        margin: 0 auto;
        padding: 20vh 0;
    }
}

@media screen and (max-width: 576px){
    ul.menu-links{
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
        padding: 0;
    }

    .menu-links .link{
        height: fit-content;
        position: relative;
    }

    .menu-links .link a:not(.mobile-dropdown-trigger) .fa-caret-down{
        position: absolute;
        top: 1.8rem;
        right: 0;
        margin: 0 5px;
    }

    .menu-links .link .mobile-dropdown-trigger{
        display: block;
    }


}

/* Dropdown menu styles */

.dropdown-trigger:hover .dropdown-menu{
    opacity: 1!important;
}

#support-us-dropdown.dropdown-menu,
#faq-dropdown.dropdown-menu{
    position: absolute;
    /*left: 10vw; 
    top: calc(20vh + 3em); */
    list-style: none;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

@media screen and (max-width: 610px) {
    #support-us-dropdown.dropdown-menu{
        left: 0;
    }
}

@media screen and (max-width: 576px){
    #support-us-dropdown.dropdown-menu,
    #faq-dropdown.dropdown-menu{
        position: relative;
        top: 0;
        height: 0;
        opacity: 1;
        transition: height 0.5s ease-in-out;
        overflow: hidden;
    }

    #support-us-dropdown.dropdown-menu.opened{
        height: calc(6rem + 30px);
    }

    #faq-dropdown.dropdown-menu.opened{
        height: calc(2rem + 10px);
    }
}

.dropdown-menu .dropdown-link{
    display: flex;
    height: 2rem;
    margin-top: 10px;
}

.dropdown-menu .dropdown-link a{
    display: inline-block;
    font-size: 1.5rem;
}

